<template>
  <div class="hello">

    <div class="bg-light container-fluid" style="width: 80%;">

      <nav class="b-navbar b-navbar-expand-lg bg-light">
        <div class="container-fluid">
          <a class="navbar-brand" href="#">
            <h3>
              <span class="brand-heading" style="color: black;">KIOSK CONFIGURATOR</span>
              <span class="fs-6 ms-3 text-muted">by<img class="" style="width: 100px; margin-left: 10px" src="../assets/dovr.png"></span>
            </h3>
          </a>
        </div>
      </nav>

      <br>

      <center>
      <div class="row">
        <h3 v-if="phase === 0">Please select a desired setup.<br><br></h3>
        <div class="col-lg">
          <div>
            <b-card
                title="ALL IN ONE"
                img-src="https://images.unsplash.com/photo-1516321318423-f06f85e504b3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                img-alt="Image"
                img-top
                img-height="300px"
                style="max-width: 75%;"
                class="mb-2"
                @click="setPhase(2), toggleSeen('all-in-one')"
            >
              <b-card-text>
                A touch screen device with no external tools.<br><br>
                <p class="text-muted" style="font-size: 10px">
                  Photo by <a href="https://unsplash.com/@nicklbaert?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Niclas Illg</a>
                  on <a href="https://unsplash.com/s/photos/computer?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </p>
              </b-card-text>

            </b-card>
          </div>

        </div>
        <div class="col-lg">
          <div>
            <b-card
                title="MODULAR"
                img-src="https://images.unsplash.com/photo-1551739440-5dd934d3a94a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80"
                img-alt="Image"
                img-top
                img-height="300px"
                style="max-width: 75%;"
                class="mb-2"
                @click="setPhase(1), toggleSeen('modular')"
            >
              <b-card-text>
                A multi-device step up which includes a device, keyboard, and mouse.<br><br>
                <p class="text-muted" style="font-size: 10px">
                  Photo by <a href="https://unsplash.com/@nicklbaert?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Niclas Illg</a>
                  on <a href="https://unsplash.com/s/photos/computer?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </p>
              </b-card-text>

            </b-card>
          </div>
        </div>

      </div>
      </center>

      <br>

    </div>

    <Transition name="fade" mode="out-in">
    <div v-show="phase !== 0 && seen" class="container bg-light" style="width: 80%; border-radius: 20px">
      <div v-if="phase === 2">
        <h3>You have selected the ALL-IN-ONE option.</h3>
      </div>

      <div v-if="phase === 1">
        <h3>You have selected the MODULAR option.</h3>
      </div>

      <h5 v-if="phase !== 0">Below are the suggested devices. Please note that prices on this site may not accurately reflect Amazon prices.</h5>

      <br>

      <div style="text-align: left; padding-left: 25px;">
        <div v-if="phase === 1 && selectedOptionChrome.length && selectedOptionMonitor.length && selectedOptionKeyBoard.length && selectedOptionMouse.length" style="color: green; text-align: center;">
          You're all ready to go! You have chosen: <br>
          {{ selectedOptionChrome[0]}}<br>
          {{ selectedOptionMonitor[0]}}<br>
          {{ selectedOptionKeyBoard[0]}}<br>
          {{ selectedOptionMouse[0]}}<br><br>
          <br>
        </div>

        <div v-if="phase === 2 && selectedOptionChrome.length && selectedOptionAllInOne.length" style="color: green; text-align: center;">
          You're all ready to go! You have chosen: <br>
          {{ selectedOptionChrome[0]}}<br>
          {{ selectedOptionAllInOne[0]}}<br>
          <br>
        </div>

        <div v-if="phase !== 0 && !selectedOptionChrome.length" style="color: red; text-align: center;">Please select a Chromebox. <br></div>
        <div v-if="phase === 1 && !selectedOptionMonitor.length" style="color: red; text-align: center;">Please select a Monitor. <br></div>
        <div v-if="phase === 1 && !selectedOptionKeyBoard.length" style="color: red; text-align: center;">Please select a Keyboard. <br></div>
        <div v-if="phase === 1 && !selectedOptionMouse.length" style="color: red; text-align: center;">Please select a Mouse. <br></div>
        <div v-if="phase === 2 && !selectedOptionAllInOne.length" style="color: red; text-align: center;">Please select a Monitor. <br></div>

        <div v-if="phase === 1"><h5>Chromeboxes</h5></div>
        <div v-if="phase === 2"><h5>Chromeboxes</h5></div>
        <table>
          <tr>
            <th></th>
            <th>Type</th>
            <th>Product</th>
            <th>Price</th>
          </tr>
          <tr v-for="product in products" :key="product.price">
            <td v-if="product.type === 'Chromebox'" style="text-align: center;"><input type="checkbox" :value="product.name" v-model="selectedOptionChrome" v-on:click= "check_one_chrome()"></td>
            <td v-if="product.type === 'Chromebox'">{{ product.type }}</td>
            <td v-if="product.type === 'Chromebox'"><a :href="product.url" target="_blank" rel="noopener">{{ product.name }}</a></td>
            <td v-if="product.type === 'Chromebox'">${{ product.price }}</td>
          </tr>
        </table>

        <br>

        <div v-if="phase === 2"><h4>Touch Screen Monitors</h4></div>

        <table v-if="phase === 2">
          <tr>
            <th></th>
            <th>Type</th>
            <th>Product</th>
            <th>Price</th>
          </tr>
          <tr>
            <td style="text-align: center;"><input type="checkbox" value="Already Have - All-In-One" v-model="selectedOptionAllInOne" v-on:click= "check_one_allInOne()"></td>
            <td>All-In-One</td>
            <td>Already Have Own</td>
            <td>$0.00</td>
          </tr>
          <tr v-for="product in products" :key="product.price">
            <td v-if="product.type === 'All-In-One'" style="text-align: center;"><input type="checkbox" :value="product.name" v-model="selectedOptionAllInOne" v-on:click= "check_one_allInOne()"></td>
            <td v-if="product.type === 'All-In-One'">{{ product.type }}</td>
            <td v-if="product.type === 'All-In-One'"><a :href="product.url" target="_blank" rel="noopener">{{ product.name }}</a></td>
            <td v-if="product.type === 'All-In-One'">${{ product.price }}</td>
          </tr>
        </table>

        <br>

        <div v-if="phase === 1"><h4>Monitors</h4></div>

        <table v-if="phase === 1">
          <tr>
            <th></th>
            <th>Type</th>
            <th>Product</th>
            <th>Price</th>
          </tr>
          <tr>
            <td style="text-align: center;"><input type="checkbox" value="Already Have - Monitor" v-model="selectedOptionMonitor" v-on:click= "check_one_monitor()"></td>
            <td>Monitor</td>
            <td>Already Have Own</td>
            <td>$0.00</td>
          </tr>
          <tr v-for="product in products" :key="product.price">
            <td v-if="product.type === 'Monitor'" style="text-align: center;"><input type="checkbox" :value="product.name" v-model="selectedOptionMonitor" v-on:click= "check_one_monitor()"></td>
            <td v-if="product.type === 'Monitor'">{{ product.type }}</td>
            <td v-if="product.type === 'Monitor'"><a :href="product.url" target="_blank" rel="noopener">{{ product.name }}</a></td>
            <td v-if="product.type === 'Monitor'">${{ product.price }}</td>
          </tr>
        </table>

        <br>

        <div v-if="phase === 1"><h4>Keyboards</h4></div>

        <table v-if="phase === 1">
          <tr>
            <th></th>
            <th>Type</th>
            <th>Product</th>
            <th>Price</th>
          </tr>
          <tr>
            <td style="text-align: center;"><input type="checkbox" value="Already Have - Keyboard" v-model="selectedOptionKeyBoard" v-on:click= "check_one_keyboard()"></td>
            <td>Keyboard</td>
            <td>Already Have Own</td>
            <td>$0.00</td>
          </tr>
          <tr v-for="product in products" :key="product.price">
            <td v-if="product.type === 'Keyboard'" style="text-align: center;"><input type="checkbox" :value="product.name" v-model="selectedOptionKeyBoard" v-on:click= "check_one_keyboard()"></td>
            <td v-if="product.type === 'Keyboard'">{{ product.type }}</td>
            <td v-if="product.type === 'Keyboard'"><a :href="product.url" target="_blank" rel="noopener">{{ product.name }}</a></td>
            <td v-if="product.type === 'Keyboard'">${{ product.price }}</td>
          </tr>
        </table>

        <br>

        <div v-if="phase === 1"><h4>Mouse</h4></div>

        <table v-if="phase === 1">
          <tr>
            <th></th>
            <th>Type</th>
            <th>Product</th>
            <th>Price</th>
          </tr>
          <tr>
            <td style="text-align: center;"><input type="checkbox" value="Already Have - Mouse" v-model="selectedOptionMouse" v-on:click= "check_one_mouse()"></td>
            <td>Mouse</td>
            <td>Already Have Own</td>
            <td>$0.00</td>
          </tr>
          <tr v-for="product in products" :key="product.price">
            <td v-if="product.type === 'Mouse'" style="text-align: center;"><input type="checkbox" :value="product.name" v-model="selectedOptionMouse" v-on:click= "check_one_mouse()"></td>
            <td v-if="product.type === 'Mouse'">{{ product.type }}</td>
            <td v-if="product.type === 'Mouse'"><a :href="product.url" target="_blank" rel="noopener">{{ product.name }}</a></td>
            <td v-if="product.type === 'Mouse'">${{ product.price }}</td>
          </tr>
        </table>

      </div>
    </div>
    </Transition>

    <br>

    <footer class="text-center text-lg-start bg-light text-muted">
      <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <div class="d-none d-lg-block">
          <span>Get connected with us on social networks:</span>
        </div>
        <div>
          <a href="https://www.facebook.com/letsdoVR" target="_blank" class="me-4 text-reset"><font-awesome-icon icon="fa-brands fa-facebook" /></a>
          <a href="https://www.instagram.com/bebrilliant.io/" target="_blank" class="me-4 text-reset"><font-awesome-icon icon="fa-brands fa-instagram" /></a>
          <a href="https://vm.tiktok.com/ZTdPpMSCY/" class="me-4 text-reset"><font-awesome-icon icon="fa-brands fa-tiktok" /></a>
          <a href="https://www.dovrmedia.com/" class="me-4 text-reset"><font-awesome-icon icon="fa-solid fa-globe" /></a>
        </div>
      </section>
      <section class="">
        <div class="container-fluid text-center text-md-start mt-5">
          <div class="row">
            <div class="col-md-8 col-lg-9 col-xl-9 mx-auto mb-4">
              <img class="img-thumbnail border-0 bg-light w-25" src="../assets/dovr.png">
              <p>v2.0.0</p>
            </div>
            <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              <h6 class="text-uppercase fw-bold mb-4">Contact</h6>
              <p><font-awesome-icon icon="fa-solid fa-house" /> Newark, NJ 07105, US</p>
              <p><font-awesome-icon icon="fa-solid fa-envelope" /> info@dovrmedia.com</p>
              <p><font-awesome-icon icon="fa-solid fa-phone" /> (732)-710-2476</p>
            </div>
          </div>
        </div>
      </section>
      <div class="text-center p-4">
        © 2022 Copyright: <a class="text-reset  fw-bold text-decoration-none" href="https://dovrmedia.com">DOVR Media</a>
      </div>
    </footer>

    <h1>{{ msg }}</h1>

  </div>

  <!--Modular: https://unsplash.com/photos/wzVQp_NRIHg-->
  <!--All in one: https://unsplash.com/photos/FlPc9_VocJ4-->
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      isHovered: false,
      phase: 0,
      type: 'null',
      seen: false,
      selectedOptionChrome: [],
      selectedOptionAllInOne: [],
      selectedOptionMonitor: [],
      selectedOptionKeyBoard: [],
      selectedOptionMouse: [],
      products: [
        {
          type: 'Chromebox',
          name: 'Acer Chromebox CXI3',
          url: 'https://www.amazon.com/Acer-Chromebox-CXI3-UA91-Processor-802-11ac/dp/B08951L2W1/ref=sr_1_3?crid=3E9NHYFPP1AW3&keywords=acer+chromebox+cxi3&qid=1654713526&sprefix=acer+chromebox+%2Caps%2C117&sr=8-3',
          price: 279.00,
        },
        {
          type: 'Chromebox',
          name: 'Asus Chromebox 3 CN65',
          url: 'https://www.amazon.com/ASUS-Chromebox-i3-10110U-Storage-DisplayPort/dp/B08RHYZ2FD/ref=sr_1_7?keywords=asus+chromebox+3+cn65&qid=1654714702&sprefix=asus+chromebox+3+c%2Caps%2C137&sr=8-7',
          price: 473.36,
        },
        {
          phase: 1,
          type: 'Monitor',
          name: 'KOORUI Computer Monitor',
          url: 'https://www.amazon.com/KOORUI-Business-Computer-Monitor-Desktop/dp/B09FP4H99P/ref=sr_1_42?crid=1JMTZSS525MJ5&keywords=monitors&qid=1668733047&sprefix=monitors%2Caps%2C162&sr=8-42',
          price: 79.99,
        },
        {
          phase: 1,
          type: 'Monitor',
          name: 'Sceptre IPS Business Computer Monitor',
          url: 'https://www.amazon.com/Sceptre-Business-Computer-Speakers-E248W-FPT/dp/B087QRW81Z/ref=sr_1_46?crid=15B94RD9M17R1&keywords=business+computer+monitor&qid=1668733102&sprefix=business+computer+monito%2Caps%2C139&sr=8-46',
          price: 99.97,
        },
        {
          phase: 1,
          type: 'Monitor',
          name: 'HP P22va G4 Computer Monitor',
          url: 'https://www.amazon.com/HP-Business-Anti-Glare-Onscreen-Standard/dp/B09NJPFZX5/ref=sr_1_2?crid=1JMTZSS525MJ5&keywords=monitors&qid=1668733003&sprefix=monitors%2Caps%2C162&sr=8-2',
          price: 119.99,
        },
        {
          phase: 1,
          type: 'Keyboard',
          name: 'Dell Wired Keyboard',
          url: 'https://www.amazon.com/Dell-Wired-Keyboard-Black-580-ADMT/dp/B00ZYLMQH0/ref=sr_1_10?crid=FLIHF7KWJ65V&keywords=business+keyboard&qid=1668472925&sprefix=business+keyboar%2Caps%2C152&sr=8-10',
          price: 15.94,
        },
        {
          phase: 1,
          type: 'Keyboard',
          name: 'CHERRY Stream Keyboard',
          url: 'https://www.amazon.com/CHERRY-Stream-Keyboard-Scissors-Mechanism/dp/B07Z1KH3PL/ref=sr_1_9?crid=FLIHF7KWJ65V&keywords=business+keyboard&qid=1668472925&sprefix=business+keyboar%2Caps%2C152&sr=8-9',
          price: 24.65,
        },
        {
          phase: 1,
          type: 'Keyboard',
          name: 'Logitech MX Mechanical Wireless Illuminated Performance Keyboard',
          url: 'https://www.amazon.com/Logitech-Mechanical-Illuminated-Performance-Bluetooth/dp/B09LK1P1RD/ref=sr_1_38_mod_primary_new?crid=FLIHF7KWJ65V&keywords=business+keyboard&qid=1668472925&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sprefix=business+keyboar%2Caps%2C152&sr=8-38',
          price: 159.90,
        },
        {
          phase: 1,
          type: 'Mouse',
          name: 'Microsoft Basic Optical Mouse',
          url: 'https://www.amazon.com/Microsoft-Basic-Optical-Mouse-Business/dp/B004MWVLY6/ref=sr_1_18?crid=2SO6RB5JV5TRZ&keywords=business+mouse&qid=1668473451&sprefix=business+mou%2Caps%2C201&sr=8-18',
          price: 11.99,
        },
        {
          phase: 1,
          type: 'Mouse',
          name: 'Microsoft Comfort Mouse 4500',
          url: 'https://www.amazon.com/Microsoft-Comfort-Mouse-4500-Business/dp/B005058B4W/ref=sr_1_9?crid=2SO6RB5JV5TRZ&keywords=business+mouse&qid=1668473451&sprefix=business+mou%2Caps%2C201&sr=8-9',
          price: 15.99,
        },
        {
          phase: 1,
          type: 'Mouse',
          name: 'Logitech M705 Marathon Wireless Mouse',
          url: 'https://www.amazon.com/Logitech-M705-Wireless-Marathon-Mouse/dp/B087Z733CM/ref=sr_1_8?crid=2SO6RB5JV5TRZ&keywords=business+mouse&qid=1668473451&sprefix=business+mou%2Caps%2C201&sr=8-8',
          price: 38.35,
        },
        {
          phase: 2,
          type: 'All-In-One',
          name: 'Elo Touch 3243L',
          url: 'https://www.amazon.com/Elo-Touch-E326202-IntelliTouch-Touchmonitor/dp/B01FRBIXBO/ref=sr_1_9?crid=JPTXFPE23WLU&keywords=elo+touchscreen+monitor&qid=1664379592&qu=eyJxc2MiOiI1LjM1IiwicXNhIjoiNC40MCIsInFzcCI6IjMuNjYifQ%3D%3D&s=electronics&sprefix=elo+touc%2Celectronics%2C72&sr=1-9',
          price: 1584.33,
        },
        {
          phase: 2,
          type: 'All-In-One',
          name: 'ASUS VT229H',
          url: 'https://www.amazon.com/ASUS-VT229H-Monitor-1080P-10-Point/dp/B07P619NXM/ref=sr_1_20?crid=3QH50BAK0P0HS&keywords=display+touch+screen+kiosk&qid=1664382357&sprefix=display+touch+screen+kiosk%2Caps%2C77&sr=8-20',
          price: 235.57,
        },
        {
          phase: 2,
          type: 'All-In-One',
          name: 'Elo Touch 2201L',
          url: 'https://www.amazon.com/Elo-2201L-IntelliTouch-Touchscreen-Monitor/dp/B005GKSBTG/ref=sr_1_3?crid=JPTXFPE23WLU&keywords=elo+touchscreen+monitor&qid=1664382804&qu=eyJxc2MiOiI1LjM1IiwicXNhIjoiNC40MCIsInFzcCI6IjMuNjYifQ%3D%3D&refinements=p_n_size_browse-bin%3A3547805011%7C3547806011%7C3547807011%7C3547808011&rnid=2633086011&s=pc&sprefix=elo+touc%2Celectronics%2C72&sr=1-3',
          price: 449.62,
        },
        {
          phase: 2,
          type: 'All-In-One',
          name: 'Elo Touch 2402L',
          url: 'https://www.amazon.com/ELO-2402L-23-8-Touchscreen-Monitor/dp/B07BGVXT7M/ref=sr_1_4?crid=JPTXFPE23WLU&keywords=elo+touchscreen+monitor&qid=1664383037&qu=eyJxc2MiOiI1LjM1IiwicXNhIjoiNC40MCIsInFzcCI6IjMuNjYifQ%3D%3D&refinements=p_n_size_browse-bin%3A3547805011%7C3547806011%7C3547807011%7C3547808011&rnid=2633086011&s=pc&sprefix=elo+touc%2Celectronics%2C72&sr=1-4',
          price: 662.62,
        },
        {
          phase: 2,
          type: 'All-In-One',
          name: 'ViewSonic TD2423D',
          url: 'https://www.amazon.com/ViewSonic-TD2423D-10-Point-Screen-DisplayPort/dp/B08VFCC4G1/ref=sr_1_3?keywords=kiosk%2Btouch%2Bdisplay%2Bchrome%2Bos&qid=1664383292&s=pc&sr=1-3&th=1',
          price: 299.99,
        },
        {
          phase: 2,
          type: 'All-in-one',
          name: 'ViewSonic TD2760',
          url: 'https://www.amazon.com/ViewSonic-TD2760-10-Point-Ergonomics-DisplayPort/dp/B07S1XYQMJ/ref=sr_1_55?keywords=touch%2Bdisplay&qid=1664384410&qu=eyJxc2MiOiI1LjEwIiwicXNhIjoiMy42NCIsInFzcCI6IjAuMDAifQ%3D%3D&s=pc&sr=1-55&th=1',
          price: 519.99,
        },
      ]
    }
  },
  methods: {
    setPhase: function (i) {
      this.phase = i;
    },
    toggleSeen: function (currentType) {
      if (this.type === 'null') {
        this.type = currentType
        this.seen = true
      }
      else if (this.type !== currentType) {
        this.type = currentType
      }
      else {
        this.seen = !this.seen
      }
    },
    check_one_chrome: function(){
      this.selectedOptionChrome = [];
    },
    check_one_allInOne: function(){
      this.selectedOptionAllInOne = [];
    },
    check_one_monitor: function(){
      this.selectedOptionMonitor = [];
    },
    check_one_keyboard: function(){
      this.selectedOptionKeyBoard = [];
    },
    check_one_mouse: function() {
      this.selectedOptionMouse = [];
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1.5s;
}
.fade-enter, .fade-leave-to
  /* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* Table */
table {
  overflow-wrap: break-word;
  text-align: left;
  padding-top: 10px;
  min-width: 100%;
  color: black;
}

table td {
  font-size: 15px;
  border-bottom: 1px solid rgb(114, 114, 114);
  border-right: 1px solid rgb(102, 102, 102);
  padding: 7px 5px;
  max-width: 100%;
}

table th {
  font-size: 17px;
  font-weight: normal;
  border-bottom: 2px solid rgb(102, 102, 102);
  border-right: 1px solid rgb(102, 102, 102);
  padding-left: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: bold;
  background-color: rgb(175, 175, 175);
}

table tr:nth-child(odd) {
  background-color: rgb(218, 218, 218);
}

table tr:nth-child(even) {
  background-color: rgb(202, 202, 202);
}
</style>
